import React from "react"
import { Link, graphql } from "gatsby"

import { ArrowRightIcon } from "@heroicons/react/outline"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Navigation } from "../components/Navigation"

import configuration from "../configuration/home.json"
import { Footer } from "../components/Footer"
import { Separator } from "../components/article/Separator"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero: React.FC<{
  description: string
  title: string
  heading: string
}> = ({ description, title, heading }) => {
  return (
    <div className="relative z-10 w-full min-h-screen sm:h-screen">
      <Navigation noBorder transparent />
      <div className="absolute bottom-0 left-0 right-0 hidden w-full sm:block h-96 md:h-full md:inset-0">
        <StaticImage
          alt="Chanoyu, la cérémonie du thé"
          layout="constrained"
          imgClassName="object-cover object-center"
          className="w-full h-full"
          src="../assets/landing/hero-gradient.png"
          quality={80}
          pngOptions={{
            quality: 80,
          }}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-full sm:hidden h-96 md:h-full md:inset-0">
        <StaticImage
          alt="Le matcha et ses bienfaits"
          layout="constrained"
          imgClassName="object-cover object-center"
          className="block w-full h-full opacity-40"
          src="../assets/landing/hero-mobile.jpg"
          quality={80}
          jpgOptions={{
            quality: 80,
          }}
        />
      </div>
      <div className="relative z-10">
        <main className="max-w-6xl px-4 pb-8 mx-auto sm:pt-14 sm:px-6">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto md:col-span-6 lg:text-left">
              <div>
                <h1 className="block text-sm font-semibold tracking-wide text-gray-500 uppercase sm:text-base md:text-base">
                  {heading}
                </h1>
                <span
                  className="block mt-1 text-4xl font-extrabold tracking-tight lg:text-6xl"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              </div>
              <p
                className="mt-3 text-gray-700 sm:pr-0 sm:mt-5 sm:text-xl"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const Article = ({
  illustration,
  title,
  date,
  url,
  description,
  category,
  readingTime = 2,
}) => {
  const image = getImage(illustration)

  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="flex-shrink-0">
        <GatsbyImage
          className="object-cover w-full h-48"
          image={image}
          alt={`image d'illustration de l'article ${title}`}
        />
      </div>
      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
        <div className="flex-1">
          <p className="text-sm font-medium text-green-600">
            <Link to={`/articles/${category.url}/`} className="hover:underline">
              {category.name}
            </Link>
          </p>
          <Link to={`/articles/${category.url}/${url}/`} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            <p className="mt-3 text-base text-gray-500">{description}</p>
          </Link>
        </div>
        <div className="flex items-center mt-6">
          <div className="flex space-x-1 text-sm text-gray-500">
            <time dateTime="2020-03-10">{date}</time>
            <span aria-hidden="true">&middot;</span>
            <span>{readingTime} min de lecture</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const Section = ({ children, title, description = null, label, url, more }) => (
  <div className="container mx-auto">
    <div className="relative px-4 pt-10 pb-10 sm:pt-16 sm:px-6 lg:pt-10 lg:px-8">
      <div className="relative max-w-6xl mx-auto">
        <div className="space-y-2">
          <h3 className="font-medium text-green-600 uppercase text-regular">
            {label}
          </h3>
          <h2 className="text-3xl font-bold text-gray-800 sm:text-4xl">
            {title}
          </h2>
          {description && (
            <p className="max-w-2xl text-lg text-gray-600">{description}</p>
          )}
          <div className="flex items-center mt-8 text-lg font-medium text-green-600 group">
            <Link to={url}>{more}</Link>
            <ArrowRightIcon className="w-5 h-5 ml-4 transition-all duration-300 ease-in-out transform group-hover:translate-x-4" />
          </div>
        </div>
        <div className="grid max-w-lg gap-5 mx-auto mt-4 md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
          {children}
        </div>
      </div>
    </div>
  </div>
)

const Context = ({ title, description, category, image, url, more }) => {
  const src = getImage(image)

  return (
    <div className="container mx-auto">
      <div className="relative px-4 pt-10 pb-10 lg:pb-20 sm:pt-16 sm:px-6 lg:pt-20 lg:px-8">
        <div className="relative grid max-w-6xl mx-auto md:grid-cols-2">
          <div className="relative items-center md:flex">
            <GatsbyImage
              image={src}
              className="rounded-lg h-72 md:h-auto"
              alt={`image de la catégorie ${category}`}
            />
          </div>

          <div className="mt-10 md:mt-0 md:flex md:items-center">
            <div className="md:pl-8 lg:pl-20">
              <h3 className="font-medium text-green-600 uppercase text-regular">
                {category}
              </h3>

              <h2 className="mt-2 text-3xl font-bold text-gray-800 md:text-4xl">
                {title}
              </h2>

              <p className="mt-8 text-lg text-gray-700">{description}</p>

              <div className="flex items-center mt-8 text-lg font-medium text-green-600 group">
                <Link to={url}>{more}</Link>
                <ArrowRightIcon className="w-5 ml-4 transition-all duration-300 ease-in-out transform -5 group-hover:translate-x-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BlogIndex = ({ data, location }) => {
  const articlesByCategories = data.articlesByCategories.group
  const categories = data.categories.edges
  const blocks = data.allBlocksJson.nodes
  const $categories = categories.reduce((acculumation, { node }) => {
    acculumation[node.id.value] = {
      ...node,
      articles: articlesByCategories.find(
        ({ category }) => category === node.id.value
      ).nodes,
    }
    return acculumation
  }, {})
  const $blocks = blocks.reduce((acculumation, node) => {
    acculumation[node.id] = node
    return acculumation
  }, {})

  const getBlocksWithOrder = configuration.order.map(order => ({
    type: order.type,
    value: order.type === "section" ? $blocks[order.id] : $categories[order.id],
  }))

  return (
    <Layout>
      <SEO
        title={configuration.seo.title}
        description={configuration.seo.description}
      />
      <Hero
        title={configuration.hero.title}
        description={configuration.hero.description}
        heading={configuration.hero.heading}
      />
      {getBlocksWithOrder.map(({ type, value }, index) => {
        if (type === "separator") return <Separator key={"separator" + index} />

        if (type === "category")
          return (
            <Section
              label={value.name}
              key={value.name}
              title={value.home.title}
              description={value.description}
              url={`/articles/${value.url}/`}
              more={value.home.more}
            >
              {value.articles.slice(0, 3).map(({ frontmatter }) => (
                <Article
                  key={frontmatter.url}
                  url={frontmatter.url}
                  readingTime={frontmatter.readingTime}
                  illustration={frontmatter.image}
                  title={frontmatter.title}
                  description={frontmatter.description}
                  date={frontmatter.date}
                  category={$categories[frontmatter.category]}
                />
              ))}
            </Section>
          )

        if (type === "section")
          return (
            <Context
              key={value.title}
              category={value.label}
              title={value.title}
              description={value.description}
              url={value.url}
              more={value.more}
              image={value.illustration}
            />
          )
      })}

      <Footer />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    categories: allCategoriesJson {
      edges {
        node {
          name
          url
          description
          home {
            title
            description
            more
          }
          id: parent {
            ... on File {
              value: name
            }
          }
        }
      }
    }
    articlesByCategories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      group(field: frontmatter___category) {
        nodes {
          frontmatter {
            description
            title
            readingTime
            url
            date(formatString: "DD MMMM YYYY", locale: "fr")
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FLUID
                  quality: 100
                )
              }
            }
            category
          }
        }
        category: fieldValue
      }
    }
    recents: allMdx(
      limit: 6
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            description
            url
            title
            description
            category
          }
        }
      }
    }
    allBlocksJson {
      nodes {
        id
        description
        label
        title
        url
        more
        illustration {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              quality: 100
            )
          }
        }
      }
    }
  }
`
